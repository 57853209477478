<template>
  <Default>
    <section class="relative py-16 md:py-32 overflow-hidden z-0" style="background: no-repeat right/cover url('/asset/grid.svg'); background-color: #e9e9f2;">
      <section class="container px-4 md:px-0 mx-auto flex items-center">
        <h3 class="font-section text-3xl md:text-5xl leading-_ md:leading-section text-content whitespace-pre-line uppercase">{{ content }}</h3>
      </section>
      <section class="bg-white mt-16">
        <section class="container py-8 md:py-16 px-4 md:px-0 mx-auto md:flex md:items-center">
          <h5 class="py-4 md:py-0 md:w-1/2 font-thriv text-center text-7xl md:text-9xl leading-section md:leading-hero text-accent text-shadow-content transform -rotate-7 whitespace-pre-line">{{ tag.hero }}</h5>
          <section class="md:w-1/2">
            <h5 class="py-4 md:text-xl">{{ tag.description }}</h5>
            <section class="text-xs md:text-sm space-y-3 text-justify" v-html="tag.content"></section>
            <CTA :to="{ hash: '#contact' }" variant="my-4 bg-tertiary text-xs md:text-sm text-content">contact</CTA>
          </section>
        </section>
      </section>
    </section>
    <!-- <section class="relative overflow-hidden z-0">
      <section class="container py-16 md:py-32 mx-auto">
        <h3 class="mx-4 md:mx-32 font-section text-3xl md:text-5xl leading-_ md:leading-tight text-content text-shadow whitespace-pre-line uppercase">{{ section.hero }}</h3>
        <section class="mx-4 md:mx-auto flex flex-col items-center space-y-9">
          <section v-for="item in section.content" :key="item.tag" class="p-4 w-full md:w-80 bg-white border border-grey rounded-sm box-shadow md:even:mr-72 md:odd:ml-72"><h5 class="pb-4 font-section text-xl uppercase">{{ item.tag }}</h5><p class="text-xs" v-html="item.data"></p></section>
        </section>
      </section>
    </section> -->
    <Contact />
  </Default>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Default from '@/layout/Default.vue'

import Hero from '@/component/Hero.vue'
import Contact from '@/component/Contact.vue'

export default defineComponent({
  meta: {
    title: `Client`,
    meta: [{ name: 'description', content: `content` }]
  },
  name: `Client`,
  components: {
    Default,

    Hero,
    Contact
  },
  data() {
    return {
      content: `hand-picked
                talent for any
                project`,
      tag: {
        hero: `what is
              thriv?`,
        description: `Welcome to the Future of Work.`,
        content: '<p class="">We have vetted the greatest Freelance-talent so that you don’t have to.</p><p class="">Thriv is a trusted software development agency for companies that seek for speed and convenience. We are a Futurice family company and connect experienced entrepreneurs with engagements, onsite and remote.</p><p class="">With no bench to defend, the entrepreneur model allows us to focus on your demand solely. We respond within one business day and 90% of our clients commit to the first profile we recommend.</p>'
      },
      section: {
        hero: `how it
              works`,
        content: [
          { tag: `phase 1`, data: 'Diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in' },
          { tag: `phase 2`, data: 'Diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in' },
          { tag: `phase 3`, data: 'Diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in' }
        ]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
