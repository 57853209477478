
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Default from '@/layout/Default.vue'

import Hero from '@/component/Hero.vue'
import Contact from '@/component/Contact.vue'

export default defineComponent({
  meta: {
    title: `Client`,
    meta: [{ name: 'description', content: `content` }]
  },
  name: `Client`,
  components: {
    Default,

    Hero,
    Contact
  },
  data() {
    return {
      content: `hand-picked
                talent for any
                project`,
      tag: {
        hero: `what is
              thriv?`,
        description: `Welcome to the Future of Work.`,
        content: '<p class="">We have vetted the greatest Freelance-talent so that you don’t have to.</p><p class="">Thriv is a trusted software development agency for companies that seek for speed and convenience. We are a Futurice family company and connect experienced entrepreneurs with engagements, onsite and remote.</p><p class="">With no bench to defend, the entrepreneur model allows us to focus on your demand solely. We respond within one business day and 90% of our clients commit to the first profile we recommend.</p>'
      },
      section: {
        hero: `how it
              works`,
        content: [
          { tag: `phase 1`, data: 'Diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in' },
          { tag: `phase 2`, data: 'Diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in' },
          { tag: `phase 3`, data: 'Diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in' }
        ]
      }
    }
  }
})
