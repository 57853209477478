<template>
  <section id="contact" class="relative z-0 bg-gradient-to-b from-tertiary to-white pb-24 md:pb-48">
    <section class="absolute -z-1 top-0 right-0 bottom-0 left-0" style="background: repeat-x bottom/.5% url('/asset/wave.svg');">
    </section>
    <section class="container py-8 px-4 md:py-16 md:px-0 mx-auto">
      <h5 class="font-section text-content text-3xl md:text-5xl uppercase">get in touch!</h5>
      <section class="mx-4 md:mx-48 md:flex py-8 md:space-x-7">
        <section class="mx-auto flex flex-col md:flex-row items-center md:items-end">
          <section class="border border-grey rounded-sm box-shadow">
            <img class="object-contain" src="/asset/people/j.png" style="width: auto; max-height: 250px;" />
          </section>
          <section class="w-64 p-4 -mt-8 ml-8 md:-ml-8 md:-mb-8 bg-white border border-grey rounded-sm box-shadow">
            <p class="font-section text-content text-xl uppercase">Jaakko Taskisalo</p>
            <p class="font-section text-client text-sm">Co-Founder, Business Manager</p>
            <p class="pt-4 font-section text-xs">
              <a class="flex" href="tel:+358 40 846 1320" rel="noreferrer">+358 40 846 1320</a>
              <a class="flex" href="mailto:jaakko.taskisalo@thriv.dev?subject=thriv contact request" rel="noreferrer">jaakko.taskisalo@thriv.dev</a>
            </p>
          </section>
        </section>
        <section class="w-52 py-4 md:py-0">
          <p class="py-4 text-content text-sm font-semibold">
            Whether you have a project in mind or just want to know more, we'd love to hear from you!
          </p>
          <CTA external location="https://calendly.com/jaakko-taskisalo/client-meeting" variant="bg-client text-xs md:text-sm text-white">book a time</CTA>
        </section>
      </section>
    </section>
    <section class="container pt-8 px-4 md:pt-16 md:px-0 mx-auto text-center">
      <h5 class="font-section text-content text-xl md:text-3xl uppercase">our customers include:</h5>
      <section class="mt-4 md:mt-8 mx-auto flex flex-wrap md:flex-no-wrap items-center justify-center text-center md:space-x-7">
        <a v-for="item in section.content" :key="item.tag" class="opacity-75 hover:opacity-100 focus:opacity-100" :href="item.ref" target="_blank" rel="noreferrer"><img class="h-12 md:h-20" :src="item.data" :alt="item.key" /></a>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Contact`,
  data() {
    return {
      section: {
        content: [
          { tag: `futurice`, ref: `https://futurice.com`, data: `/asset/partner/futurice.png` },
          { tag: `cr`, ref: `https://columbiaroad.com`, data: `/asset/partner/cr.png` },
          { tag: `pockethunt`, ref: `https://pockethunt.com`, data: `/asset/partner/pockethunt.png` },
          { tag: `speechly`, ref: `https://speechly.com`, data: `/asset/partner/speechly.png` },
          { tag: `telia`, ref: `https://telia.fi`, data: `/asset/partner/telia.png` }
        ]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
