
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Contact`,
  data() {
    return {
      section: {
        content: [
          { tag: `futurice`, ref: `https://futurice.com`, data: `/asset/partner/futurice.png` },
          { tag: `cr`, ref: `https://columbiaroad.com`, data: `/asset/partner/cr.png` },
          { tag: `pockethunt`, ref: `https://pockethunt.com`, data: `/asset/partner/pockethunt.png` },
          { tag: `speechly`, ref: `https://speechly.com`, data: `/asset/partner/speechly.png` },
          { tag: `telia`, ref: `https://telia.fi`, data: `/asset/partner/telia.png` }
        ]
      }
    }
  }
})
